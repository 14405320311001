import { Route, Switch } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Homepage from './home';
import CommonHeader from './CommonHeader';
import ViewReport from './view/ViewReport';
import SubmitReportPage from './submit/SubmitReportPage';
import SpearsApiFactory from "../spears-api/SpearsApiFactory";

export default function App() {
  const SpearsApi = SpearsApiFactory();

  async function getCurrentUser() {
    const response = (await SpearsApi.getRequesterInfo()).data;
    // console.log("typeof response",typeof response);
    // console.log("response", response);

    const requesterInfo =
      (typeof response === 'string' || response instanceof String) ?  JSON.parse(response as string) : response;

    return requesterInfo.user;
  }

  const [currentUser, setCurrentUser] = useState("");
  useEffect(() => {
    (async () => { setCurrentUser(await getCurrentUser()); })();
  }, []);

  return (
    <div>
      <CommonHeader currentUser={currentUser}/>
      <Switch>
        <Route path="/view/:reportId" component={ViewReport} />
        <Route path="/submit/:cloneReportId" component={SubmitReportPage} />
        <Route exact path="/" render={() => <Homepage currentUser={currentUser}/> } />
        <Route exact path="/:weblabIdFromParams" render={() => <Homepage currentUser={currentUser}/> } />
      </Switch>
    </div>
  );
}
