import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Container from '@amzn/awsui-components-react/polaris/container';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import DetailsTable from '../query/ReportTable';

// The content in the main content area of the App layout
export default function HomepageContent(props) {
  return (
    <>
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 12, l: 12, s: 12, xxs: 12 } }
          ]}
        >
          <SpaceBetween size="l">
            <div>
              <Container>
                <DetailsTable {...props}/>
              </Container>
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
    </>
  );
}
