import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useCollection } from '@amzn/awsui-collection-hooks';
import  {
  Box,
  Button,
  Header,
  Pagination,
  Table,
  TextFilter,
  Input,
  SpaceBetween,
  Modal,
  CollectionPreferences, CollectionPreferencesProps
} from '@amzn/awsui-components-react/polaris';
import { COLUMN_DEFINITIONS, getMatchesCountText, EmptyState, paginationLabels } from './table-config';
import SpearsApiFactory from '../../spears-api/SpearsApiFactory';
import {ReportSummary, ReportState} from '../../spears-api/generated-src/api';
import {prepareFormattedReportSummaryFields} from "../../common/utils";

export default function ReportsTable(props) {
  const {weblabIdFromParams} = useParams<{weblabIdFromParams: string}>();
  const [allItems, setAllItems] = useState([].map(r=>prepareFormattedReportSummaryFields(r)));
  const [weblabId, setWeblabId] = useState(weblabIdFromParams);
  const [isLoading, setIsLoading] = useState(false);
  const [actionModalState,setActionModalState] = useState({ dismissible: false, header: "", content: ""});
  const [deleteConfirmationModalState, setDeleteConfirmationModalState] = useState({show: false, reportId: ""});
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 50,
    visibleContent: ['view_link','marketplace_name','start_date','end_date','total_days','state','details','actions']
  });

  const SpearsApi = SpearsApiFactory();

  async function reportAction(e, action:string, reportId: string) {
    // console.log("window.location",window.location);

    const reportURL = `${window.location.origin}/#/${action}/${reportId}`;

    switch (action) {
      case "submit":
        const reportSubmitWindow = window.open(reportURL, "reportSubmitWindow", `width=${window.outerWidth},height=${window.outerHeight}`);
        reportSubmitWindow?.location.reload(); // force refresh in the window

        break;

      case "view":

        const openInNewTab = true;

        var reportWindow;
        if (openInNewTab) {
          reportWindow = window.open(reportURL, "_blank");
        } else {
          const windowName = reportId; // to open each report in its own separate window
          // const windowName = "reportViewWindow"; // alternatively to open each report in the same "view window"
          reportWindow = window.open(reportURL, windowName, `width=${window.outerWidth},height=${window.outerHeight}`);
        }
        reportWindow.location.reload(); // force refresh in the window

        break;
      case "delete":
        e.stopPropagation();
        setDeleteConfirmationModalState({show: true, reportId: reportId});
        break;
      default:
        throw `Invalid action ${action}`;
    }
  }

  async function handleReportDeletion(reportId) {
    const modalHeader = `Report ${reportId}`;
    setActionModalState({ dismissible: false, header: modalHeader, content: "Deletion in progress..." });

    // TODO remove deletion block
    // alert(`Report deletion is temporarily disabled. Otherwise you would have gotten the message:
    // Successfully deleted report ${reportId}`);
    // await reloadReports(weblabId);
    // return;

    SpearsApi.deleteReport(reportId).then(response => {
      setActionModalState({ dismissible: true, header: modalHeader, content: "Report deleted"});
      // refresh table
      reloadReports(weblabId);
    }).catch(error => {
      // console.log("SpearsApi.deleteReport() exception",JSON.stringify(error.response));
      setActionModalState({ dismissible: true, header: modalHeader,
        content: `Report deletion failed:\n ${error.response.data.errorMessage}`,
      });
    });
  }

  const newReportButton = (
    <Button variant="primary" iconName="add-plus" onClick={(e) => reportAction(e,'submit',"new")}>
      New Report
    </Button>
  );

  async function reloadReports(weblabId:string) {

    setAllItems([]);
    setIsLoading(true);
    window.document.body.style.cursor = 'wait';

    const rawReportSummaries = await queryReports(weblabId);
    setAllItems(rawReportSummaries.map(r=>prepareFormattedReportSummaryFields(r)));

    setIsLoading(false);
    window.document.body.style.cursor = 'default';
    window.history.replaceState({}, '', `${window.location.origin}/#/${weblabId}`);
  }

  async function queryReports(weblabId:string) {

    if (!weblabId) return [];

    const canonicalizedWeblabId = weblabId.trim().toUpperCase();
    // const stateFilter = ['SUCCEEDED','RUNNING','SUBMITTED','FAILED'];
    const stateFilter = [ReportState.Succeeded,ReportState.Running,ReportState.Submitted,ReportState.Failed];
    const response = (await SpearsApi.queryReports(canonicalizedWeblabId,stateFilter)).data;
    // console.log("typeof response",typeof response);
    // console.log("response", response);

    if (typeof response === 'string' || response instanceof String) {
      return []
    } else {
      return (response.report_summaries as ReportSummary[]).filter(r => r.version == "20211201")
    }
  }

  useEffect(() => {
    (async () => {
      if (weblabId) reloadReports(weblabId);
    })();
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="No records"
            subtitle="No records to display."
            action={<div/>}
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        )
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: { defaultState : {sortingColumn :{ sortingField: "updated_on"}, isDescending: true }},
      selection: {}
    }
  );

  const { selectedItems } = collectionProps;
  return (<>
    <Modal visible={isLoading} header={`Reports for weblab ${weblabId}`}>
      <h4>Loading...</h4>
    </Modal>
    <Modal visible={ deleteConfirmationModalState.show } size="large"
      onDismiss={() => setDeleteConfirmationModalState({show: false, reportId: ""})}
      header={`Confirm report deletion`}
      footer= { <Box float="right"> <SpaceBetween direction="horizontal" size="m">
          <Button onClick={() => setDeleteConfirmationModalState({show: false, reportId: ""})}>Cancel</Button>
          <Button variant="primary"
            onClick={() => {
              const reportIdToBeDeleted = deleteConfirmationModalState.reportId;
              setDeleteConfirmationModalState({show: false, reportId: ""});
              handleReportDeletion(reportIdToBeDeleted);
            }}>Ok</Button>
      </SpaceBetween></Box> }>
      <h4>{`Are you sure you want to delete report ${deleteConfirmationModalState.reportId}?`}</h4>
    </Modal>
    <Modal visible={ Boolean(actionModalState.header) } size="large"
      onDismiss={() => setActionModalState({header:"",content:"",dismissible:true})}
      header={actionModalState.header}
      footer={ actionModalState.dismissible ? <Button variant="primary" onClick={()=>setActionModalState({header:"",content:"",dismissible:true})}>Ok</Button> : <></>} >
      <h4>{actionModalState.content}</h4>
    </Modal>
    <Table
      {...collectionProps}
      loading={!allItems}
      loadingText="Loading instances"
      //selectionType="single"
      header={<>
        <Box variant="h2">
          <table><tbody><tr>
              <td>SION Weblab</td>
              <td width="250px"><Input
                value={weblabId}
                ariaRequired={true}
                placeholder="enter weblab id"
                onChange={({ detail: { value } }) => setWeblabId(value.trim())}
                onKeyDown={({ detail: { key } }) => { if (key === 'Enter') { reloadReports(weblabId as string); }}}
              /></td>
              <td><Button variant="primary" iconName="angle-right-double" disabled={isLoading}
                  onClick={() => reloadReports(weblabId as string)}>
                Query
              </Button></td>
              <td>{newReportButton}</td>
          </tr></tbody></table>
        </Box>
        <Header variant="h3" headingTagOverride="h5"
          counter={
            allItems &&
            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
          }
        >
          Reports
        </Header>
      </>}
      columnDefinitions={COLUMN_DEFINITIONS(props,preferences,reportAction)}
      visibleColumns={preferences.visibleContent}
      trackBy={item => item.report_id}
      items={items}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter among the reports below"
          filteringPlaceholder="Filter among the reports below"
        />
      }
      preferences={
        <CollectionPreferences
          title="Report List Display Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          onConfirm={({ detail }) => setPreferences(detail)}
          preferences={preferences}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 reports" },
              { value: 20, label: "20 reports" },
              { value: 50, label: "50 reports" }
            ]
          }}
          visibleContentPreference={{
            title: "Select visible columns",
            options: [
              {
                label: "",
                options: [
                  { id: "total_days", label: "#Days", editable: true },
                  { id: "details", label: "Report Details", editable: true },
                  { id: "owner", label: "Owner", editable: true },
                  { id: "updated_on", label: "Last Updated", editable: true },
                  { id: "report_id", label: "Report Id", editable: true },
                ]
              }
            ]
          }}
        />
      }
    />
  </>);
}

