// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { getMidwayJwtToken } from './auth/MidwayJwtToken';
import axios from 'axios';
import { initializeAppSettings } from './config/AppSettings';
import SpearsApiFactory from "./spears-api/SpearsApiFactory";
import {MARKETPLACE_MAP} from "./common/utils";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  // initialize marketplace map
  const configuration = (await SpearsApiFactory().getPortalConfiguration()).data;
  // console.log("getConfiguration:",configuration);
  configuration.marketplaces.forEach(m => MARKETPLACE_MAP.set(m.id,m));

  render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.querySelector('#app')
  );
})();
