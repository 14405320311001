import { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Form,
  Header,
  SpaceBetween,
  CollectionPreferences,
  Multiselect,MultiselectProps,SelectProps,
  FormField,
  Input,
  Link,
} from "@amzn/awsui-components-react/polaris";
import SpearsApiFactory from '../../spears-api/SpearsApiFactory'
import {CreditMethod, ReportInfo, SionResult} from '../../spears-api/generated-src/api';
import {
  dedup,
  getUniqueId,
  intersperseWith,
  METRIC_MAP,
  prepareFormattedReportSummaryFields,
  renderSlicingDimension,
  renderSlicingDimensionValue,
  SLICING_DIMENSION_MAP,
  findMatchingTEMModel,
  computeLiftStatistics,
  applyTEMModel,
  padStart,
  stateToColor,
  NBSP,
  parseRealNumber,
  isRealNumber, checkRange, isIntegerNumber, validateFloat, validateInt, CREDIT_METHOD_MAP, sortSionResultsCanonically,
} from '../../common/utils';

interface ReportSignature {
  slices: Map<string,string[]>;
  metrics:  string[];
  credit_methods:  string[];
  arms:  string[];
};

interface ImpactConverterOptionsInfo {
  metric: string;
  credit_method: string;
  slice: Object;
  impact_converter_options : {
    apt_search_attributed_mean: string;  // from "Overall: Mean" column for line "First Discovery Reftag: sr_ + mp_s:T1" in APT
    apt_sitewide_mean: string;           // from "Overall: Mean" column for line "all:T1" in APT
    apt_sitewide_percent_impact: string; // from "Overall: %Impact" column for line "all:T1" in APT in "Impact: Impact" mode
    apt_sitewide_annualized: string;     // from "Overall: Annual" column for line "all:T1" in APT in "Impact: Annualized" mode
  };
};

interface ImpactConverterOptions {
  number_of_lanes : string;
  options_map: Map<string,ImpactConverterOptionsInfo>;
};

export default function ViewReport() {
  const history = useHistory();
  const {reportId} = useParams<{reportId: string}>();

  const SpearsApi = SpearsApiFactory();

  const [reportInfo, setReportInfo] = useState<ReportInfo>();
  const [error, setError] = useState<string>();
  const [fullReportSignature, setFullReportSignature] = useState<ReportSignature>({
    slices: new Map(),
    metrics: [],
    credit_methods: [],
    arms: []
  });
  const [reportSignature, setReportSignature] = useState(fullReportSignature);
  const [impactConverterOptions, setImpactConverterOptions] = useState<ImpactConverterOptions>({
    number_of_lanes : "",
    options_map : new  Map<string,ImpactConverterOptionsInfo>()
  });


  async function getReportInfo(reportId) {
    const response = (await SpearsApi.getReportInfo(reportId)).data;
    // console.log("typeof response",typeof response);
    // console.log("response", response);

    if (typeof response === 'string' || response instanceof String) {
      return JSON.parse(response as string) as ReportInfo;
    } else {
      return response as ReportInfo;
    }
  }

  function computeInferredInfo(reportInfo) {
    // apply matching TEM models to produce estimated_lift_stats and add inferred confidence intervals for lift as lift_stats
    for (const arm of reportInfo.report_results.interleaved_arms) {
      for (const sion_result of arm.sion_results) {
        const sion_test = sion_result.sion_test;

        const conf_level = sion_test.conf_level ?? reportInfo.report_parameters.confidence_level;

        sion_test.lift_stats = computeLiftStatistics(sion_test,conf_level);

        const tem_model = findMatchingTEMModel(sion_result, reportInfo);
        // console.log("tem_model:",tem_model);
        if (tem_model != null) {
          sion_test.estimated_lift_stats = applyTEMModel(tem_model,sion_test,conf_level);
        }
      }
    }
    return reportInfo;
  }

  useEffect(() => {
    (async () => {
      setError(undefined);
      try {
        const reportInfo = await getReportInfo(reportId);
        if (reportInfo != null) {
          const augmentedReportInfo = computeInferredInfo(reportInfo);
          setReportInfo(augmentedReportInfo);
          const collectedReportSignature = collectReportSignature(reportInfo);
          setFullReportSignature(collectedReportSignature);
          setReportSignature({...collectedReportSignature, credit_methods: [] }); // use default credit methods
          setImpactConverterOptions({
            number_of_lanes: "",
            options_map: prepareImpactConverterOptionsMap(augmentedReportInfo)
          });
          window.document.title = `SION Report ${reportId}`;
        } else {
          throw Error(`SION Report "${reportId}" not found`)
        }
      } catch (e) {
          setError(e.toString());
      }
    })();
  }, []);

  console.log('reportInfo',reportInfo);

  const errorDiv = !error ? '' :
      <div className="error" style={{fontSize:"18px",color:"red",textAlign:"center"}}>
        {error}
      </div>
  ;

  function FNCell(props) {
    const {style, children, ...other_props} = props;
    return <td style={{border: "1px solid black",  borderCollapse: "collapse", ...style}} {...other_props}>{children}</td>;
  }

  const renderedReportInfo = !reportInfo ? '' :
      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal"
                        onClick={()=>{window.open("", "_self"); window.close();}}>
                  Close
                </Button>

              </SpaceBetween>
            }
            header= {
              <Container disableContentPaddings
                header={
                  <FormattedReportSummary reportInfo={reportInfo} fullReportSignature={fullReportSignature}
                      reportSignature={reportSignature} setReportSignature={setReportSignature}
                      impactConverterOptions={impactConverterOptions} setImpactConverterOptions={setImpactConverterOptions}/>
              }>

                <FormattedReportErrors reportInfo={reportInfo}/>
              </Container>
            }
          >
              <FormattedReportResults reportInfo={reportInfo} reportSignature={reportSignature}
                      impactConverterOptions={impactConverterOptions}/>
          </Form>
        { !isImpactConversionApplicable(impactConverterOptions) ? '' : <>
          <hr/>
          <h3>(*) Calculations used for weblab impact estimates:</h3>
            <em>
              "Estimated Sitewide Lift" = "Estimated Lift" * ("APT Search-attributed Mean" / "APT Site-wide Mean" )<br/>
              "Estimated Annualized Gain" = "Estimated Sitewide Lift" * ( "APT Site-wide Annual" / "APT Site-wide % Impact in APT) * (20 lanes / "Number of lanes for the APT report"))<br/>
            </em><br/>
          <b>Number of traffic lanes in the weblab for the APT report : <em>{impactConverterOptions.number_of_lanes}</em></b><br/>
          <table style={{borderCollapse: "collapse"}}><tbody>
            {[...impactConverterOptions.options_map.entries()].map(([key,info],i,arr) => {
              if (isImpactConverterOptionsDefined(info)) {
                const opts = info["impact_converter_options"];
                return <Fragment key={getUniqueId()}>
                  <tr style={{textAlign: "left"}}><FNCell colSpan={4}>{renderSIONResultSignature(info)}</FNCell></tr>
                  <tr style={{fontWeight: "bold", textAlign: "center"}}>
                    <FNCell>Search-attributed Mean</FNCell><FNCell>Site-wide Mean</FNCell><FNCell>Site-wide %
                    Impact</FNCell><FNCell>Site-wide Annual Impact</FNCell></tr>
                  <tr style={{fontWeight: "bold", textAlign: "center"}}>
                    <FNCell>{opts.apt_search_attributed_mean}</FNCell><FNCell>{opts.apt_sitewide_mean}</FNCell><FNCell>{opts.apt_sitewide_percent_impact}</FNCell><FNCell>{opts.apt_sitewide_annualized}</FNCell>
                  </tr>
                </Fragment>;
              }
            })}
           </tbody></table>
          </>
        }
      </Box>
  ;

  return (errorDiv || renderedReportInfo) ?
    <>
      {errorDiv}
      {renderedReportInfo}
    </>
    :
    <BannerBox title="Loading..."/>
  ;
}

export function BannerBox( props : {title: string, subtitle?: string }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {props.title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {props.subtitle}
      </Box>
    </Box>
  );
}

function TCell(props) {
  const ownStyle = {border: "0px solid black", textAlign: "center"}
  const {style, children, ...other_props} = props;
  const merged_style = {...ownStyle, ...style}
  return <td style={merged_style} {...other_props}>{children}</td>;
}

export function FormattedReportSummary(props) {
  const ri =  props.reportInfo;
  const downloadLinkRef = useRef(null);

  const rp = ri.report_parameters;

  console.log("FormattedReportSummary: ",ri);
  // for (const k of Object.getOwnPropertyNames(ri)) {
  //   console.log("FormattedReportSummary: entries:",k,':',ri[k]);
  // }

  const formattedSummary = prepareFormattedReportSummaryFields(ri);
  const reportBlob = new Blob([JSON.stringify(ri, null, 2)], {type : 'application/json'});

  const formattedDescription = !formattedSummary.description ? <></> :
    <>
      <tr><TCell colSpan={7}>
        <hr/>
      </TCell></tr>
      <tr style={{textAlign: "left"}}>
        <TCell style={{fontWeight: "bold"}}> Description: </TCell>
        <TCell style={{textAlign: "left"}} colSpan={6}> {intersperseWith(formattedSummary.description.split("\n"), () => <br key={getUniqueId()}/>)} </TCell>
      </tr>
    </>
  ;

  return <table style={{width: "100%", borderCollapse: "collapse"}}><tbody>
    <tr style={{fontWeight: "bold"}}>
      <TCell> Weblab Id </TCell>
      <TCell> Marketplace Id</TCell>
      <TCell colSpan={3}> Report Id </TCell>
      <TCell style={{textAlign: "right", padding:"0px"}}>
        <span title="Report view options">
          <ReportViewOptions {...props}/>
        </span>
      </TCell>
      <TCell style={{textAlign: "right", padding:"0px"}}>
      <span title="Clone report">
        <Button variant="inline-icon" iconName="copy" onClick={()=> {
            const reportURL = window.location.href.replace("/view/","/submit/");
            const reportSubmitWindow = window.open(reportURL, "reportSubmitWindow", `width=${window.outerWidth},height=${window.outerHeight}`);
            reportSubmitWindow?.location.reload(); // force refresh in the window
          }}
        />
      </span>
        <span title="Download report as JSON">
          <a ref={downloadLinkRef} download={`${ri.report_id}.json`} href={window.URL.createObjectURL(reportBlob)}/>
          <Button variant="inline-icon" iconName="download" onClick={()=>{(downloadLinkRef.current! as HTMLElement).click()}}/>
        </span>
        <span title="Close window">
          <Button variant="inline-icon" iconName="close" onClick={()=>{window.open("", "_self"); window.close();}}/>
        </span>
      </TCell>
    </tr>
    <tr>
      <TCell> <Link href={`${window.location.origin}/#/${rp.weblab_id}`}>{rp.weblab_id}</Link> </TCell>
      <TCell> {formattedSummary.marketplace_name} </TCell>
      <TCell colSpan={3}> {ri.report_id} </TCell>
      <TCell colSpan={2}/>
    </tr>
    <tr style={{fontWeight: "bold"}}>
      <TCell colSpan={2}> Dates </TCell>
      <TCell> Created On </TCell>
      <TCell> Updated On </TCell>
      <TCell> Owner </TCell>
      <TCell colSpan={2}> State </TCell>
    </tr>
    <tr>
      <TCell colSpan={2}> {formattedSummary.start_date} .... {formattedSummary.end_date} ( {formattedSummary.total_days} days) </TCell>
      <TCell> {formattedSummary.created_on}  </TCell>
      <TCell> {formattedSummary.updated_on}  </TCell>
      <TCell> {formattedSummary.owner}  </TCell>
      <TCell colSpan={2}> <span style={{color: stateToColor(formattedSummary.state)}}> {formattedSummary.state} </span> </TCell>
    </tr>
    {formattedDescription}
  </tbody></table>;
}


function ReportResultLine(props) {
  const ownStyle = {border: "1px solid black", textAlign: "center", width: "100%", borderCollapse: "collapse", borderSpacing: "0px"}
  const {style, children, ...other_props} = props;
  const merged_style = {...ownStyle, ...style}

  return <table cellPadding="0" cellSpacing="0" style={merged_style} {...other_props}><tbody>{children}</tbody></table>;

}

function renderConfidenceLevel(confidence_level) {
  return ((1. - confidence_level) * 100.).toFixed(0) + "%"
}
function renderValue(value,usePercent=false) {
  if (value == null) return '-';
  return usePercent ? `${(100. * value).toFixed(2)}%` : value.toFixed(3);
}

function renderStats(stats, confidence_level, usePercent=false) {
  if (!stats) return "";

  const conf_level = stats.conf_level ?? confidence_level;
  const formatted_conf_level = conf_level == confidence_level ? "" : renderConfidenceLevel(conf_level);
  const value = renderValue(stats.mean, usePercent);
  const conf_int_l = renderValue(stats.conf_interval[0] ?? 0., usePercent);
  const conf_int_r = renderValue(stats.conf_interval[1] ?? 0., usePercent);
  const desiredMinWidth = usePercent ? 6 : 6;
  const padder = (x) => padStart(x,desiredMinWidth,' ');
  return stats.mean == null ? "-" :
    <div style={{lineHeight: "0.9em"}}>
      &nbsp;{padder(value)}<br/><span style={{fontSize:"80%"}}>({padder(conf_int_l)}, {padder(conf_int_r)})&nbsp;{formatted_conf_level}</span>
    </div>;
}

function FormattedReportResults(props) {
  const ri = props.reportInfo;
  const reportSignature: ReportSignature = props.reportSignature;
  console.log("reportSignature",reportSignature);

  const useImpactConversion = isImpactConversionApplicable(props.impactConverterOptions);

  if (Object.keys(ri).length == 0 || ri.report_parameters == undefined) return  <BannerBox title="UNDEFINED..."/>;

  console.log("FormattedReportResults:",ri.report_parameters);

  const confidence_level = ri.report_parameters.confidence_level;

  function RRCell(props) {
    const ownStyle = {border: "1px solid black"}
    const {style, children, ...other_props} = props;
    const merged_style = {...ownStyle, ...style}
    return <TCell style={merged_style} {...other_props}>{children}</TCell>;
  }

  function renderSionTestResults(arm) {

    const sion_results = sortSionResultsCanonically(arm.sion_results);

    // extract base analysis unit count from debug metadata
    // support pre-'analysis_unit'-style reports using hardcoded name for 'session_count'
    const analysisUnit = ri.report_parameters.analysis_unit ? ri.report_parameters.analysis_unit : "session";
    const analysisUnitCountFieldName = ri.report_parameters.analysis_unit ? 'analysis_unit_count' : 'session_count';

    const observedArmsMap = ri.report_results?.metadata?.misc_debug_info?.observed_interleaved_arms_map;
    const baseAnalysisUnitCount = !observedArmsMap ? -1 :
      Object.entries(observedArmsMap[arm.arm]).map(([k,v]) => Object(v)[analysisUnitCountFieldName]).reduce((a,b) => a + b, 0);

    // "signal" = metric+credit_method combo
    const signal_heading = <tr key="signal_heading" style={{fontWeight: "bold"}}>
        <RRCell>Metric</RRCell>
        {[...reportSignature.slices.keys()].map((sd) => <RRCell key={"header-"+sd}>{renderSlicingDimension(sd)}</RRCell>)}
        <RRCell>Estimated Lift ({renderConfidenceLevel(confidence_level)} CI)</RRCell>
        { useImpactConversion && <>
            <RRCell>Estimated Sitewide Lift</RRCell>
            <RRCell>Estimated Annualized Gain</RRCell>
          </>
        }
        <RRCell>SION Credit Lift ({renderConfidenceLevel(confidence_level)} CI)</RRCell>
        <RRCell>p-value</RRCell>
        <RRCell>SION Credit Difference ({renderConfidenceLevel(confidence_level)} CI)</RRCell>
      </tr>;

    // build "default method" entry for result_map. we use "" as a (pseudo)method name
    const DEFAULT_CREDIT_METHOD = "";

    const result_map =
      new Map([...reportSignature.credit_methods,DEFAULT_CREDIT_METHOD].map(cm => [cm, new Map(reportSignature.metrics.map(m => [ m, new Array()] ))] ));

    for (const r of sion_results) {
      // if slicing dimension or dimension value is not in reportSignature.slices - skip result
      // but always keep "global" slices
      const allSlicingDimensionValuesSelected = Object.keys(r.slice).every(sd => {
        return !r.slice[sd] || (reportSignature.slices.get(sd) ?? []).indexOf(r.slice[sd]) >= 0
      });
      if (allSlicingDimensionValuesSelected) {
        result_map.get(r.credit_method)?.get(r.metric)?.push(r);
        // also add to "default" result map if credit method is default for this metric
        if (r.credit_method == (METRIC_MAP.get(r.metric)?.default_method || CreditMethod.AdjustedCreditV2)) {
          result_map.get(DEFAULT_CREDIT_METHOD)?.get(r.metric)?.push(r);
        }
      }
    }
    const sample_size = sion_results[0].sion_test.sample_size;
    const baseAnalysisUnitCountInfo = baseAnalysisUnitCount < 0 ? '' :
      ` (of ${baseAnalysisUnitCount} (${((100. * sample_size)/baseAnalysisUnitCount).toFixed(1)}%))`;

    const result_rows = [
      <ReportResultLine style={{fontWeight: "bold"}}><tr style={{padding:"5px"}}>
        <td>{NBSP(9)}</td>
        <td> {arm.arm} </td>
        <td>{NBSP(6)}</td>
        <td> A = {arm.ranker_A} {NBSP(2)} — {NBSP(2)} B = {arm.ranker_B}</td>
        <td>{NBSP(6)}</td>
        <td> {analysisUnit == "customer_id" ? "Customer" : "Session"} Count: {sample_size}{baseAnalysisUnitCountInfo}</td>
      </tr></ReportResultLine>,
    ];

    for (const credit_method of result_map.keys()) {

      if (result_map.size > 1) {
        // if non-default credit methods are selected (then both non-default and default are present in result_map),
        // skip the default one
        if (credit_method == DEFAULT_CREDIT_METHOD) continue;

        // otherwise output the name of the non-default credit method
        const creditMethodInfo = CREDIT_METHOD_MAP.get(credit_method);
        result_rows.push(
          <ReportResultLine style={{fontWeight: "bold", border: "1px solid"}}>
            <tr>
              <td style={{padding: "5px"}}>
                {credit_method}{creditMethodInfo?` (${creditMethodInfo.description})`:''}
              </td>
            </tr>
          </ReportResultLine>
        );
      }
      const signal_result_rows = [signal_heading];

      for (const metric of result_map.get(credit_method)!.keys()) {
        for(const slice_result of result_map.get(credit_method)!.get(metric)!.values()) {

          // render slice_results

          const slice = slice_result.slice;

          // note that slice_results are already lexicographically ordered by construction
          // this guarantees that "no slicing" result will be the first
          // we use this property to populate metric name only for the first line of the slice section
          const metric_label = METRIC_MAP.get(metric as string)?.label ?? metric;
          const metric_cell_value = [...reportSignature.slices.keys()].every(sd => (slice[sd] ?? "") == "") ? metric_label : "";
          const slice_parts = [...reportSignature.slices.keys()].map(sd =>
            <RRCell key={"slice_part-"+sd} style={{textAlign: "center", padding:"5px"}}>{renderSlicingDimensionValue(sd,(slice[sd] ?? ""))}</RRCell>);

          const sion_test = slice_result.sion_test;

          const p_value = sion_test.p_value ?? 0.;
          //const formatted_p_value = p_value < 1e-6 ? p_value.toExponential(6) : p_value.toPrecision(10)

          const conf_level = sion_test.conf_level ?? confidence_level;
          const cellColor = (p_value > conf_level) ? "black" : (sion_test.mean < 0 ? "red" : "green");
          const cellFontWeight = (p_value > conf_level) ? "400" : "600";
          const cellStyle = {color: cellColor, fontWeight: cellFontWeight};

          const impactEstimates = applyImpactConverterOptions(props.impactConverterOptions,slice_result);

          signal_result_rows.push(
            <tr key={getUniqueId()}>
              <RRCell style={{textAlign: "left", padding:"5px"}}>{metric_cell_value}</RRCell>
              {slice_parts}
              <RRCell style={cellStyle}>{renderStats(sion_test.estimated_lift_stats,conf_level,true)}</RRCell>
              {useImpactConversion && <>
                  <RRCell style={cellStyle}>{impactEstimates && renderValue(impactEstimates?.sitewide_lift, true)}</RRCell>
                  <RRCell style={cellStyle}>{impactEstimates && impactEstimates?.annualized_gain.toLocaleString('en-US', { style: 'currency', currency: 'USD',maximumFractionDigits: 0  }) }</RRCell>
                </>
              }
              <RRCell style={cellStyle}>{renderStats(sion_test.lift_stats,conf_level,true)} </RRCell>
              <RRCell style={cellStyle}>{p_value.toFixed(4)}</RRCell>
              <RRCell style={cellStyle}>{renderStats(sion_test,conf_level)}</RRCell>
            </tr>
          );
        }
      }
      result_rows.push(
        <ReportResultLine>{signal_result_rows}</ReportResultLine>
      );
    }
    return <ReportResultLine key={"arm-"+arm.arm} style={{ border: "1px solid black"}}>
      {result_rows.map(row=> <tr key={getUniqueId()}><td>{row}</td></tr>)}
    </ReportResultLine>;
  }
  const padder = () => <table key={getUniqueId()} cellPadding="0" cellSpacing="1" style={{border:"0px", padding:"0px"}}><tbody><tr><td></td></tr></tbody></table>;
  const treatmentArmsMap = new Map(ri.report_results.interleaved_arms.map(x => [x.arm,x]))
  return intersperseWith(reportSignature.arms.map((arm) => renderSionTestResults(treatmentArmsMap.get(arm))),padder);
}

export function FormattedReportErrors(props) {
  // Test error list
  const testErrorList = [
    "error1\ndsdfsfsd\nrewrwrewrewr",
    "error2",
    "errors"
  ];
  // const errorList = testErrorList;
  const errorList = props.reportInfo.errors;

  return (errorList.length == 0) ? <div/> :
    <ExpandableSection header={<div style={{color:"red",fontSize:"16px"}}> Report Errors </div>}>
      {errorList.map((e) => {
        console.log("Report error:",e);
        // TODO convert error into a multiline message
        const errorString = (typeof e == "object" && "exception" in e ) ? e['exception'] : String(e);
        const errorParts = errorString.split("\n");
          return <ul><li key={getUniqueId()}>{errorParts[0]}
            {(errorParts.length == 1)? '' :
             <ExpandableSection key={getUniqueId()} header="..."><pre>{errorString}</pre></ExpandableSection>}
          </li></ul>
      })}
    </ExpandableSection>
  ;
}

function collectReportSignature(reportInfo:ReportInfo): ReportSignature {
  var all_slices = new Map<string,string[]>();
  var all_metrics: string[] = [];
  var all_credit_methods: string[] = [];

  reportInfo.report_results.interleaved_arms.forEach( arm => {
    arm.sion_results.forEach(r => {
      Object.keys(r.slice).forEach(sd => {
        if (r.slice[sd]) {
          // only collect slicing dimensions with non-empty
          const slice_values = all_slices.get(sd)?? [];
          all_slices.set(sd, dedup([...slice_values, r.slice[sd]]));
        }
      });
      all_metrics.push(r.metric);
      all_credit_methods.push(r.credit_method);
    });
  });

  all_metrics = dedup(all_metrics).sort();
  all_credit_methods = dedup(all_credit_methods).sort();

  // console.log("all_slices",all_slices);
  // console.log("all_metrics",all_metrics);
  // console.log("all_credit_methods",all_credit_methods);

  const arms = reportInfo.report_results.interleaved_arms.map(r => r.arm);
  const sortedArms = arms.sort((a,b) => (a.length != b.length) ? a.length - b.length : a.localeCompare(b));
  return {
    slices: all_slices,
    metrics: all_metrics,
    credit_methods: all_credit_methods,
    arms: sortedArms
  };
}

function ReportViewOptions(props) {
  const { reportSignature, setReportSignature, fullReportSignature, impactConverterOptions, setImpactConverterOptions } = props;

  const [newReportSignature, setNewReportSignature] = useState(fullReportSignature);
  const [newImpactConverterOptions, setNewImpactConverterOptions] = useState(impactConverterOptions);

  useEffect(() => {
      setNewReportSignature(reportSignature);
      setNewImpactConverterOptions(impactConverterOptions);
  },[reportSignature,impactConverterOptions]);

  // const metricGroup = {
  //   label: "Metrics",
  //   options: [...METRIC_MAP].map(([m,i]) => ({value: m,label: i.label }))
  // };
  function makeMetricOptions(metrics) {
    return metrics.map(m => ({value: m, label: METRIC_MAP.get(m)?.label ?? m }));
  }

  function makeArmsOptions(arms) {
    return arms.map(a => ({value: a, label: a }));
  }
  function makeSliceOptions(slices,sd) {
    return slices.get(sd)?.map(v => ({value: v, label: SLICING_DIMENSION_MAP.get(sd)?.values.get(v) ?? v }));
  }

  function makeCreditMethodOptions(credit_methods) {
    return credit_methods.map(cm => ({value: cm, label: cm }));
  }

  function OptionsSelector(label,allOptions,selectedOptions,onChangeCallback,description?) {
    return <SpaceBetween direction="vertical" size="xs" key={label}>
      <Header variant="h3" headingTagOverride="h5" description={description??""}>{label}</Header>
      <FormField stretch>
        <Multiselect
          selectedOptions={selectedOptions}
          onChange={e => onChangeCallback(e)}
          deselectAriaLabel={e => `Remove ${e.label}`}
          options={allOptions}
          placeholder={`Choose ${label}`}
          selectedAriaLabel="Selected"
        />
      </FormField>
      <hr/>
    </SpaceBetween>;
  }

  function WeblabImpactConverterOptions(props) {
    const onChangeHandler = (key, attribute, value) => {
      const clonedOptions = {...newImpactConverterOptions};
      (clonedOptions.options_map.get(key)!)["impact_converter_options"][attribute] = value;
      setNewImpactConverterOptions(clonedOptions);
    };

    const newImpactConverterOptionsUI = [...newImpactConverterOptions.options_map.entries()].map(([key,info],i,arr) => {
      const impact_converter_options = info["impact_converter_options"];

      return <Container disableContentPaddings header={renderSIONResultSignature(info)} key={key}>
        <table style={{border:"0px"}}><tbody><tr>
          <td/>
          <td><FormField label="Search-attributed Mean">
            <Input autoComplete={false}
                   invalid={!validateFloat(normalizeAPTAmount(impact_converter_options.apt_search_attributed_mean), [0., Number.MAX_VALUE])}
                   value={impact_converter_options.apt_search_attributed_mean} placeholder='"Mean" for "Reftag: sr_ + mp_s"'
                   onChange={({detail: {value}}) => onChangeHandler(key, "apt_search_attributed_mean", value)}/>
          </FormField></td>
          <td><FormField label="Site-wide Mean">
            <Input autoComplete={false}
                   invalid={!validateFloat(normalizeAPTAmount(impact_converter_options.apt_sitewide_mean), [0., Number.MAX_VALUE])}
                   value={impact_converter_options.apt_sitewide_mean} placeholder='"Mean" for "all"'
                   onChange={({detail: {value}}) => onChangeHandler(key, "apt_sitewide_mean", value)}/>
          </FormField></td>
          <td><FormField label="Site-wide % Impact">
            <Input autoComplete={false}
                   invalid={!validateFloat(normalizeAPTAmount(impact_converter_options.apt_sitewide_percent_impact), [0., 100.])}
                   value={impact_converter_options.apt_sitewide_percent_impact} placeholder='"%Impact" for "all" in "Impact" mode'
                   onChange={({detail: {value}}) => onChangeHandler(key, "apt_sitewide_percent_impact", value)}/>
          </FormField></td>
          <td><FormField label="Site-wide Annual Impact">
            <Input autoComplete={false}
                   invalid={!validateFloat(normalizeAPTAmount(impact_converter_options.apt_sitewide_annualized), [0., Number.MAX_VALUE])}
                   value={impact_converter_options.apt_sitewide_annualized} placeholder='"Annual" for "all" in "Annualized" mode'
                   onChange={({detail: {value}}) => onChangeHandler(key, "apt_sitewide_annualized", value)}/>
          </FormField></td>
        </tr></tbody></table>
      </Container>
    });

    return <Fragment key="Weblab Impact Conversion Factors">
      <Header variant="h3" headingTagOverride="h5"
        info={<Link external href="https://quip-amazon.com/y18iANPnny7H/SION-handbook-for-estimating-AB-sitewide-and-annualized-gain">Info</Link>}
        description={<>
        To estimate weblab impact choose a related APT report as described in the "Info" link above and copy respective values from <b>p-value</b> (frequentist) view
          to the fields below for supported metric/slice combination of interest (you only need to provide values for sections with metric/slice combinations of interest,
          but field "Number of traffic lanes" is common and required for all of them.) <br/>
          Use the APT metrics for treatment T1, and columns "Mean(USD)", "% Impact", and "Annual" (when switched to "Annualized" view) from "Overall" column group.
          For site-wide values use section "all", for search-attributed use section "First Discovery Reftag: sr_ + mp_s".
          For OPS sliced by "Days To Deliver: 0-1 Days" use APT metrics marked with "Promise Speed: 0 + 1".
        </>}
      >
        Estimated Weblab Impact Conversion Inputs
      </Header>
      <FormField label="Number of traffic lanes in the weblab from the APT report">
        <div style={{width:"25%"}}>
        <Input autoComplete={false} invalid={!validateInt(newImpactConverterOptions.number_of_lanes, [0, 20])}
               value={newImpactConverterOptions.number_of_lanes} placeholder=""
               onChange={({detail: {value}}) => { const clonedOptions = {...newImpactConverterOptions};
                 clonedOptions.number_of_lanes = value; setNewImpactConverterOptions(clonedOptions);
               }}/>
      </div>
      </FormField>
      {newImpactConverterOptionsUI}
    </Fragment>;
  }

  const [preferences, setPreferences] = useState({
    custom: ""
  });

  const metricSelector = OptionsSelector("Metrics",
    makeMetricOptions(fullReportSignature.metrics), makeMetricOptions(newReportSignature.metrics),
    ({detail}) => {
    const newSelectedMetrics = detail.selectedOptions.map(o => o.value);
    setNewReportSignature({...newReportSignature, metrics: newSelectedMetrics});
  });
  const treatmentSelector = OptionsSelector("Interleaved Treatments",
    makeArmsOptions(fullReportSignature.arms),  makeArmsOptions(newReportSignature.arms),
    ({detail}) => {
      const newSelectedArms = detail.selectedOptions.map(o => o.value);
      setNewReportSignature({...newReportSignature, arms: newSelectedArms});
  });
  const sliceSelectors = [...fullReportSignature.slices.keys()].map(sd =>
    OptionsSelector(SLICING_DIMENSION_MAP.get(sd)?.label,
      makeSliceOptions(fullReportSignature.slices,sd), makeSliceOptions(newReportSignature.slices,sd),
      ({detail}) => {
        const newSelectedSliceValues = detail.selectedOptions.map(o => o.value);
        const newSelectedSlices = new Map(newReportSignature.slices);
        newSelectedSlices.set(sd,newSelectedSliceValues);
        setNewReportSignature({...newReportSignature, slices: newSelectedSlices});
    })
  );
  const creditMethodSelector = OptionsSelector("Credit Methods",
    makeCreditMethodOptions(fullReportSignature.credit_methods), makeCreditMethodOptions(newReportSignature.credit_methods),
    ({detail}) => {
      const newSelectedCreditMethods = detail.selectedOptions.map(o => o.value);
      setNewReportSignature({...newReportSignature, credit_methods: newSelectedCreditMethods});
    },
  "If no credit methods are selected then the final results are shown with the metrics being paired with their default preferred credit method");

  return (
    <CollectionPreferences
      onConfirm={({ detail }) => {
        setReportSignature(newReportSignature);
        const normalizedOptions = normalizeImpactConverterOptions(newImpactConverterOptions);
        console.log("normalized newImpactConverterOptions",normalizedOptions);
        if(isImpactConverterOptionsValid(normalizedOptions)) { setImpactConverterOptions(normalizedOptions); }
      }}
      onCancel={() => { setNewReportSignature(reportSignature); setNewImpactConverterOptions(impactConverterOptions) }}
      preferences={preferences}
      customPreference={(dummyValue, setDummyValue) => (<>
        {[metricSelector, ...sliceSelectors, treatmentSelector, WeblabImpactConverterOptions(props), <hr/>, creditMethodSelector]}
      </>)}
      cancelLabel="Cancel"
      confirmLabel="Confirm"
      title="Report View Options"
    />
  );
}

function renderSIONResultSignature(sionResult) {
  const {metric, credit_method, slice } = sionResult;

  const metricLabel = <><b>Metric:</b> {METRIC_MAP.get(metric as string)?.label ?? metric} {NBSP(5)}</>;
  const methodSpec = credit_method != "adjusted_credit_v2" ? <><b>Method: </b> {credit_method} {NBSP(5)}</> : "" ;

  const canonicalSlice = Object.entries(slice).filter(([k,v]) => v != "").sort().map(([sd,sv])=> {
    const dimension_label = SLICING_DIMENSION_MAP.get(sd)?.label ?? sd;
    const dimension_value_label = SLICING_DIMENSION_MAP.get(sd)?.values.get(sv as string) ?? sv as string;
    return <Fragment key={sd+"__"+sv}> {dimension_label} : <i>{dimension_value_label}</i></Fragment>;
  });

  const sliceSpec = canonicalSlice.length ? <><b>Slice: </b> {intersperseWith(canonicalSlice,";")}</> : "";
  return <> { metricLabel } { methodSpec }{ sliceSpec } </>;
}

function makeCanonicalKeyForSIONResult(result) {
   return JSON.stringify(Object.entries({
     metric: result.metric,
     slice: JSON.stringify(Object.entries(result.slice).filter(([k,v]) => v != "").sort()),
     credit_method: result.credit_method
   }).sort());
}

function prepareImpactConverterOptionsMap(reportInfo) {
  const impactConverterOptionsMap = new Map<string,ImpactConverterOptionsInfo>();
  for(const arm of reportInfo.report_results.interleaved_arms) {
    for(const result of arm.sion_results) {
      if (result.sion_test.estimated_lift_stats) {
        const {metric, credit_method, slice} = result;
        impactConverterOptionsMap.set(makeCanonicalKeyForSIONResult(result),
          {metric, credit_method, slice, impact_converter_options : {
            apt_search_attributed_mean: "", apt_sitewide_mean: "",
            apt_sitewide_annualized: "", apt_sitewide_percent_impact: ""
          }});
      }
    }
  }
  return impactConverterOptionsMap;
}

function normalizeAPTAmount(value) {
  //strip from optional commas, currency at the beginning and % at the end
  return value.trim()
    .replace(/,/g, '')
    .replace(/^\$/,'')
    .replace(/\%$/,'');
}

function normalizeImpactConverterOptions(impactConverterOptions:ImpactConverterOptions): ImpactConverterOptions {
  function normalizeImpactConverterOptionsInfo(impactConverterOptionsInfo:ImpactConverterOptionsInfo) {
    const options = impactConverterOptionsInfo.impact_converter_options;
    const normalizedOptions = {...impactConverterOptionsInfo,
      impact_converter_options : {
        apt_search_attributed_mean: normalizeAPTAmount(options.apt_search_attributed_mean),
        apt_sitewide_mean: normalizeAPTAmount(options.apt_sitewide_mean),
        apt_sitewide_percent_impact: normalizeAPTAmount(options.apt_sitewide_percent_impact),
        apt_sitewide_annualized: normalizeAPTAmount(options.apt_sitewide_annualized)
      }
    };
    return normalizedOptions;
  }
  return {
    ...impactConverterOptions,
    options_map: new Map([...impactConverterOptions.options_map.entries()].map(([k, v]) =>
        [k, normalizeImpactConverterOptionsInfo(v)]))
  };
}

function isImpactConverterOptionsValid(impactConverterOptions:ImpactConverterOptions) {
  return validateInt(impactConverterOptions.number_of_lanes, [0.,20.]) &&
    [...impactConverterOptions.options_map.values()].every( optionsInfo => {
      const options = optionsInfo.impact_converter_options;
      return validateFloat(options.apt_search_attributed_mean), [0., Number.MAX_VALUE] &&
        validateFloat(options.apt_sitewide_mean), [0., Number.MAX_VALUE] &&
        validateFloat(options.apt_sitewide_percent_impact), [0., 1.0] &&
        validateFloat(options.apt_sitewide_annualized), [0., Number.MAX_VALUE];

    });
}

function isImpactConverterOptionsDefined(optionsInfo:ImpactConverterOptionsInfo) {
  const options = optionsInfo.impact_converter_options;
  return options.apt_search_attributed_mean && options.apt_sitewide_mean &&
         options.apt_sitewide_percent_impact && options.apt_sitewide_annualized;
}

function isImpactConversionApplicable(impactConverterOptions) {
  return impactConverterOptions.number_of_lanes > 0 &&
    [...impactConverterOptions.options_map.values()].some( x => isImpactConverterOptionsDefined(x));
}

function applyImpactConverterOptions(impactConverterOptions:ImpactConverterOptions,sionResult) {
  // find matching
  const slicedMetricOptions = impactConverterOptions.options_map.get(makeCanonicalKeyForSIONResult(sionResult));

  if (slicedMetricOptions && isImpactConverterOptionsDefined(slicedMetricOptions) && sionResult.sion_test.estimated_lift_stats) {
    const impact_options = slicedMetricOptions.impact_converter_options;

    const sitewide_lift = sionResult.sion_test.estimated_lift_stats.mean *
        parseFloat(impact_options.apt_search_attributed_mean) / parseFloat(impact_options.apt_sitewide_mean);
    const annualized_gain = sitewide_lift *
        (parseFloat(impact_options.apt_sitewide_annualized) / (parseFloat(impact_options.apt_sitewide_percent_impact) / 100.)) *
        (20. / parseInt(impactConverterOptions.number_of_lanes));

    return {
      sitewide_lift : sitewide_lift,
      annualized_gain : annualized_gain
    }
  } else { return null; }
}

