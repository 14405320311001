/* tslint:disable */
/* eslint-disable */
/**
 * SPEARS Portal API
 * SION Post-experiment Analysis Report Service(SPEARS) Portal API
 *
 * The version of the OpenAPI document: 2022-01-31
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Analysis unit used to match weblab randomization type
 * @export
 * @enum {string}
 */

export enum AnalysisUnit {
    Session = 'session',
    CustomerId = 'customer_id'
}

/**
 * SION credit methods
 * @export
 * @enum {string}
 */

export enum CreditMethod {
    AdjustedCreditV2 = 'adjusted_credit_v2',
    BinaryAdjustedCreditV2 = 'binary_adjusted_credit_v2',
    AdjustedCredit = 'adjusted_credit',
    AdjustedCreditV2ImpressionNormalized = 'adjusted_credit_v2__impression_normalized'
}

/**
 * 
 * @export
 * @interface CustomGLSet
 */
export interface CustomGLSet {
    /**
     * 
     * @type {string}
     * @memberof CustomGLSet
     */
    'set_name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomGLSet
     */
    'gl_product_groups': Array<string>;
}
/**
 * 
 * @export
 * @interface ErrorResponseContent
 */
export interface ErrorResponseContent {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponseContent
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseContent
     */
    'errorMessage': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseContent
     */
    'errorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponseContent
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface InterleavedArm
 */
export interface InterleavedArm {
    /**
     * 
     * @type {string}
     * @memberof InterleavedArm
     */
    'arm': string;
    /**
     * 
     * @type {string}
     * @memberof InterleavedArm
     */
    'ranker_A': string;
    /**
     * 
     * @type {string}
     * @memberof InterleavedArm
     */
    'ranker_B': string;
    /**
     * 
     * @type {Array<SionResult>}
     * @memberof InterleavedArm
     */
    'sion_results': Array<SionResult>;
}
/**
 * SION metrics
 * @export
 * @enum {string}
 */

export enum Metric {
    Ops = 'ops',
    NumAdds = 'num_adds',
    NumClicks = 'num_clicks',
    NumPaidUnits = 'num_paid_units',
    NumPaidPurchases = 'num_paid_purchases',
    NumBorrows = 'num_borrows',
    OpsIndicator = 'ops_indicator',
    NumAddsIndicator = 'num_adds_indicator',
    NumClicksIndicator = 'num_clicks_indicator'
}

/**
 * Message
 * @export
 * @interface NoSuchResourceResponseContent
 */
export interface NoSuchResourceResponseContent {
    /**
     * 
     * @type {string}
     * @memberof NoSuchResourceResponseContent
     */
    'message'?: string;
}
/**
 * Report Parameters
 * @export
 * @interface OutputReportParameters
 */
export interface OutputReportParameters {
    /**
     * 
     * @type {string}
     * @memberof OutputReportParameters
     */
    'weblab_id': string;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'marketplace_id': number;
    /**
     * 
     * @type {string}
     * @memberof OutputReportParameters
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof OutputReportParameters
     */
    'end_date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputReportParameters
     */
    'exclude_dates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OutputReportParameters
     */
    'description'?: string;
    /**
     * 
     * @type {AnalysisUnit}
     * @memberof OutputReportParameters
     */
    'analysis_unit'?: AnalysisUnit;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputReportParameters
     */
    'interleaved_arms'?: Array<string>;
    /**
     * 
     * @type {Array<ResultScope>}
     * @memberof OutputReportParameters
     */
    'result_scopes'?: Array<ResultScope>;
    /**
     * 
     * @type {Array<CustomGLSet>}
     * @memberof OutputReportParameters
     */
    'custom_gl_set'?: Array<CustomGLSet>;
    /**
     * 
     * @type {Array<CreditMethod>}
     * @memberof OutputReportParameters
     */
    'credit_methods'?: Array<CreditMethod>;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'confidence_level'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'winsorization_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'tommy_barista_unique_join_ratio_threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'tommy_barista_match_eligible_ratio_threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputReportParameters
     */
    'tommy_barista_match_ratio_threshold'?: number;
    /**
     * 
     * @type {Array<SliceSpecification>}
     * @memberof OutputReportParameters
     */
    'slice_space': Array<SliceSpecification>;
}
/**
 * A paged array of report summaries
 * @export
 * @interface QueryReportsResponseContent
 */
export interface QueryReportsResponseContent {
    /**
     * 
     * @type {Array<ReportSummary>}
     * @memberof QueryReportsResponseContent
     */
    'report_summaries'?: Array<ReportSummary>;
}
/**
 * Report record
 * @export
 * @interface ReportInfo
 */
export interface ReportInfo {
    /**
     * 
     * @type {string}
     * @memberof ReportInfo
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof ReportInfo
     */
    'report_id': string;
    /**
     * 
     * @type {any}
     * @memberof ReportInfo
     */
    'owner': any;
    /**
     * 
     * @type {ReportState}
     * @memberof ReportInfo
     */
    'state': ReportState;
    /**
     * 
     * @type {string}
     * @memberof ReportInfo
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof ReportInfo
     */
    'updated_on': string;
    /**
     * 
     * @type {OutputReportParameters}
     * @memberof ReportInfo
     */
    'report_parameters': OutputReportParameters;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportInfo
     */
    'errors': Array<string>;
    /**
     * 
     * @type {ReportResults}
     * @memberof ReportInfo
     */
    'report_results': ReportResults;
}
/**
 * 
 * @export
 * @interface ReportResults
 */
export interface ReportResults {
    /**
     * 
     * @type {any}
     * @memberof ReportResults
     */
    'metadata'?: any;
    /**
     * 
     * @type {Array<InterleavedArm>}
     * @memberof ReportResults
     */
    'interleaved_arms': Array<InterleavedArm>;
}
/**
 * SPEARS report states
 * @export
 * @enum {string}
 */

export enum ReportState {
    Succeeded = 'SUCCEEDED',
    Running = 'RUNNING',
    Submitted = 'SUBMITTED',
    Failed = 'FAILED'
}

/**
 * Report summary
 * @export
 * @interface ReportSummary
 */
export interface ReportSummary {
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    'report_id': string;
    /**
     * 
     * @type {any}
     * @memberof ReportSummary
     */
    'owner': any;
    /**
     * 
     * @type {ReportState}
     * @memberof ReportSummary
     */
    'state': ReportState;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    'updated_on': string;
    /**
     * 
     * @type {OutputReportParameters}
     * @memberof ReportSummary
     */
    'report_parameters': OutputReportParameters;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportSummary
     */
    'errors': Array<string>;
}
/**
 * 
 * @export
 * @interface ResultScope
 */
export interface ResultScope {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof ResultScope
     */
    'metrics': Array<Metric>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ResultScope
     */
    'slice_map'?: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface SionResult
 */
export interface SionResult {
    /**
     * 
     * @type {Metric}
     * @memberof SionResult
     */
    'metric': Metric;
    /**
     * 
     * @type {string}
     * @memberof SionResult
     */
    'credit_method': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SionResult
     */
    'slice': { [key: string]: string; };
    /**
     * 
     * @type {SionTest}
     * @memberof SionResult
     */
    'sion_test': SionTest;
}
/**
 * 
 * @export
 * @interface SionTest
 */
export interface SionTest {
    /**
     * 
     * @type {number}
     * @memberof SionTest
     */
    'p_value': number;
    /**
     * 
     * @type {number}
     * @memberof SionTest
     */
    'mean': number;
    /**
     * 
     * @type {number}
     * @memberof SionTest
     */
    'lift': number;
    /**
     * 
     * @type {number}
     * @memberof SionTest
     */
    'conf_level': number;
    /**
     * 
     * @type {number}
     * @memberof SionTest
     */
    'sample_size': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SionTest
     */
    'conf_interval': Array<number>;
}
/**
 * 
 * @export
 * @interface SliceSpecification
 */
export interface SliceSpecification {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof SliceSpecification
     */
    'metrics': Array<Metric>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SliceSpecification
     */
    'slice': { [key: string]: string; };
}
/**
 * Report Parameters
 * @export
 * @interface SubmitReportRequestContent
 */
export interface SubmitReportRequestContent {
    /**
     * 
     * @type {string}
     * @memberof SubmitReportRequestContent
     */
    'weblab_id': string;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'marketplace_id': number;
    /**
     * 
     * @type {string}
     * @memberof SubmitReportRequestContent
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitReportRequestContent
     */
    'end_date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitReportRequestContent
     */
    'exclude_dates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubmitReportRequestContent
     */
    'description'?: string;
    /**
     * 
     * @type {AnalysisUnit}
     * @memberof SubmitReportRequestContent
     */
    'analysis_unit'?: AnalysisUnit;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmitReportRequestContent
     */
    'interleaved_arms'?: Array<string>;
    /**
     * 
     * @type {Array<ResultScope>}
     * @memberof SubmitReportRequestContent
     */
    'result_scopes'?: Array<ResultScope>;
    /**
     * 
     * @type {Array<CustomGLSet>}
     * @memberof SubmitReportRequestContent
     */
    'custom_gl_set'?: Array<CustomGLSet>;
    /**
     * 
     * @type {Array<CreditMethod>}
     * @memberof SubmitReportRequestContent
     */
    'credit_methods'?: Array<CreditMethod>;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'confidence_level'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'winsorization_percent'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'tommy_barista_unique_join_ratio_threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'tommy_barista_match_eligible_ratio_threshold'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubmitReportRequestContent
     */
    'tommy_barista_match_ratio_threshold'?: number;
}

/**
 * SpearsApi - axios parameter creator
 * @export
 */
export const SpearsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReport', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific report by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portal_configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific report by id
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportInfo: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportInfo', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequesterInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all reports
         * @param {string} weblabId Specifies weblab id for which reports are queried
         * @param {Array<ReportState>} [state] Specifies optional list of states to be used to filter reports
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryReports: async (weblabId: string, state?: Array<ReportState>, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'weblabId' is not null or undefined
            assertParamExists('queryReports', 'weblabId', weblabId)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (weblabId !== undefined) {
                localVarQueryParameter['weblab_id'] = weblabId;
            }

            if (state) {
                localVarQueryParameter['state'] = state;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a report
         * @param {SubmitReportRequestContent} submitReportRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitReport: async (submitReportRequestContent: SubmitReportRequestContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submitReportRequestContent' is not null or undefined
            assertParamExists('submitReport', 'submitReportRequestContent', submitReportRequestContent)
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication aws.auth.sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitReportRequestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpearsApi - functional programming interface
 * @export
 */
export const SpearsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpearsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReport(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReport(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific report by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPortalConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific report by id
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportInfo(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportInfo(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequesterInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequesterInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all reports
         * @param {string} weblabId Specifies weblab id for which reports are queried
         * @param {Array<ReportState>} [state] Specifies optional list of states to be used to filter reports
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryReports(weblabId: string, state?: Array<ReportState>, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryReportsResponseContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryReports(weblabId, state, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a report
         * @param {SubmitReportRequestContent} submitReportRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitReport(submitReportRequestContent: SubmitReportRequestContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitReport(submitReportRequestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpearsApi - factory interface
 * @export
 */
export const SpearsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpearsApiFp(configuration)
    return {
        /**
         * Delete report by id
         * @param {string} reportId The id of the report to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReport(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific report by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalConfiguration(options?: any): AxiosPromise<any> {
            return localVarFp.getPortalConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific report by id
         * @param {string} reportId The id of the report to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportInfo(reportId: string, options?: any): AxiosPromise<ReportInfo> {
            return localVarFp.getReportInfo(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Identity of the API caller
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequesterInfo(options?: any): AxiosPromise<any> {
            return localVarFp.getRequesterInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * List all reports
         * @param {string} weblabId Specifies weblab id for which reports are queried
         * @param {Array<ReportState>} [state] Specifies optional list of states to be used to filter reports
         * @param {number} [limit] How many items to return at one time (max 100)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryReports(weblabId: string, state?: Array<ReportState>, limit?: number, options?: any): AxiosPromise<QueryReportsResponseContent> {
            return localVarFp.queryReports(weblabId, state, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a report
         * @param {SubmitReportRequestContent} submitReportRequestContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitReport(submitReportRequestContent: SubmitReportRequestContent, options?: any): AxiosPromise<string> {
            return localVarFp.submitReport(submitReportRequestContent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpearsApi - object-oriented interface
 * @export
 * @class SpearsApi
 * @extends {BaseAPI}
 */
export class SpearsApi extends BaseAPI {
    /**
     * Delete report by id
     * @param {string} reportId The id of the report to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public deleteReport(reportId: string, options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).deleteReport(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific report by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public getPortalConfiguration(options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).getPortalConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific report by id
     * @param {string} reportId The id of the report to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public getReportInfo(reportId: string, options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).getReportInfo(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Identity of the API caller
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public getRequesterInfo(options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).getRequesterInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all reports
     * @param {string} weblabId Specifies weblab id for which reports are queried
     * @param {Array<ReportState>} [state] Specifies optional list of states to be used to filter reports
     * @param {number} [limit] How many items to return at one time (max 100)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public queryReports(weblabId: string, state?: Array<ReportState>, limit?: number, options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).queryReports(weblabId, state, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a report
     * @param {SubmitReportRequestContent} submitReportRequestContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpearsApi
     */
    public submitReport(submitReportRequestContent: SubmitReportRequestContent, options?: AxiosRequestConfig) {
        return SpearsApiFp(this.configuration).submitReport(submitReportRequestContent, options).then((request) => request(this.axios, this.basePath));
    }
}


