import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Link from '@amzn/awsui-components-react/polaris/link';
import * as tokens from '@amzn/awsui-design-tokens';

export default function CommonHeader(props) {
  const currentUser = props.currentUser;

  return (
    <div style={{ background: tokens.colorBackgroundHomeHeader }}>
      <Box padding={{ vertical: 's', horizontal: 's' }}>
        <Grid
          gridDefinition={[{ colspan: 11}, { colspan: 0}]}
        >
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } }
            ]}
          >
            <div className="custom-home-text-inverted">
              <Box variant="h4" fontWeight="bold" padding="n" fontSize="heading-l" color="inherit">
                <span style={{color:"lightblue"}}>SPEARS™</span> : SION Post-Experiment Analysis Report Service Portal v.0.11.0
              </Box>
              <Box variant="h5" fontWeight="light" fontSize="heading-s">
                <span className="custom-home-text-secondary">
                   Boost your Amazon Search experimentation bandwidth :  &nbsp;
                  <Link href="https://w.amazon.com/bin/view/Search/A9/Relevance/ExperimentationInfraAndMethods/Projects/ION/IntroGuide/" target="_blank">
                    SION User Guide
                  </Link>
                </span>
              </Box>
            </div>
          </Grid>
          <div>
              <img height="50px" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${currentUser}`} alt={currentUser} title={currentUser}/>
          </div>
        </Grid>
      </Box>
    </div>
  );
}