import {jStat} from "jstat";
import {SliceSpecification, SionTest, CreditMethod} from "../spears-api/generated-src";

export const MARKETPLACE_MAP = new Map();

export const METRIC_MAP = new Map([
  ["ops", { label: "OPS", default_method: CreditMethod.AdjustedCreditV2 }],
  ["ops_indicator", { label: "OPS Indicator", default_method: CreditMethod.BinaryAdjustedCreditV2 }],
  ["num_paid_units", { label: "Paid Units", default_method: CreditMethod.AdjustedCreditV2 }],
  ["num_adds", { label: "Cart Adds", default_method: CreditMethod.AdjustedCreditV2 }],
  ["num_adds_indicator", { label: "Cart Adds Indicator", default_method: CreditMethod.BinaryAdjustedCreditV2 }],
  ["num_clicks", { label: "Clicks", default_method: CreditMethod.AdjustedCreditV2 }],
  ["num_clicks_indicator", { label: "Clicks Indicator", default_method: CreditMethod.BinaryAdjustedCreditV2 }],
  ["num_paid_purchases", { label: "Paid Purchases", default_method: CreditMethod.AdjustedCreditV2 }],
  ["num_borrows", { label: "Kindle Borrows", default_method: CreditMethod.AdjustedCreditV2 }],
]);

export const CREDIT_METHOD_MAP = new Map([
  ["adjusted_credit_v2", {
    description: "default preferred credit method for non-indicator metrics and also suitable for indicator metrics"
  }],
  ["binary_adjusted_credit_v2", {
    description: "default preferred credit method for indicator metrics but not suitable for non-indicator metrics"
  }],
]);

export const SLICING_DIMENSION_MAP = new Map([
  ["days_to_deliver",
    { label: 'Days To Deliver',
      values: new Map([
        ["dtd_0", "0 Days"], ["dtd_1","1 Day"], ["dtd_2","2 Days"], ["dtd_0to1","0-1 Days"], ["dtd_0to2","0-2 Days"]
      ])
    }
  ],
  ["gl_set",
    { label: 'GL Product Group',
      values: new Map([
        ["softlines", "Softlines"], ["hardlines","Hardlines"],
      ])
    }
  ],
]);
export function renderSlicingDimension(sd) {
  return SLICING_DIMENSION_MAP.get(sd)?.label ?? sd;
}
export function renderSlicingDimensionValue(sd,sdv) {
  return SLICING_DIMENSION_MAP.get(sd)?.values?.get(sdv) ?? sdv;
}

export function padStart(str,targetLength,padString) {
    targetLength = targetLength>>0; //floor if number or convert non-number to 0;
    padString = String(padString || ' ');
    if (str.length > targetLength) {
        return String(str);
    }
    else {
        targetLength = targetLength-str.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0,targetLength) + String(str);
    }
};

export function formatDate(d:Date, format:string="yyyy-MM-dd") {
  const yyyy = d.getFullYear();
  const MM = padStart((d.getMonth() + 1).toString(), 2, '0');
  const dd = padStart(d.getDate().toString(),2, '0');
  switch (format) {
    case "yyyy-MM-dd": return`${yyyy}-${MM}-${dd}`;
    case "yyyyMMdd"  : return`${yyyy}${MM}${dd}`;
    case "yyyy/MM/dd": return`${yyyy}/${MM}/${dd}`;
    default:
      throw `Unknown date format "${format}"`;
  }
}
export function parseDate(dateString:string, format:string="yyyyMMdd") {
  function validateDate(y: number, m: number, d: number) {
    const date = new Date(y, m, d);
    if (date.getFullYear() === +y && date.getMonth() === +m && date.getDate() === +d) {
      return date;
    } else {
      throw `Invalid date '${dateString}' for format '${format}'`;
    }
  }

  switch (format) {
    case "yyyyMMdd": {
        const y = parseInt(dateString.substr(0, 4));
        const m = parseInt(dateString.substr(4, 2)) - 1;
        const d = parseInt(dateString.substr(6, 2));
        return validateDate(y, m, d);
      }
      break;
    case "yyyy/MM/dd": // TODO add checks for separator
    case "yyyy-MM-dd":
      {
        const y = parseInt(dateString.substr(0, 4));
        const m = parseInt(dateString.substr(5, 2)) - 1;
        const d = parseInt(dateString.substr(8, 2));
        return validateDate(y, m, d);
      }
    default:
      throw `Unknown date format "${format}"`;
  }
}

export function getTimezoneOffsetMillis(timezone:string): number {
  // returns time difference in millis between UTC and timezone
  const d = new Date(); // dummy date
  const utcDate =  new Date(d.toLocaleString('en-US', {timeZone: "UTC"}));
  const timezoneDate =  new Date(d.toLocaleString('en-US', {timeZone: timezone}));
  return utcDate.getTime() - timezoneDate.getTime();
}

export function flatten<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr);
}
export function unflatten<T>(arr: T[]): T[][] {
  return arr.map(x=>[x]);
}

export function intersperseWith(array, separator) {
  const separatorWrapper = (typeof separator === "function") ? separator : (() => separator)
  return array.length <= 1 ? array : array.slice(1).reduce( (a,e) => [...a, separatorWrapper(), e],[array[0]]);
}

export function dedup<T>(arr:T[]): T[] {
  return Array.from(new Set(arr));
}

export function daysBetween(date1,date2) {
  const diff = Math.floor(date2.getTime() - date1.getTime());
  const millisecondsInDay = 1000 * 60 * 60 * 24;

  // Using Math.round() below is essential to compensate for occasional daylight savings time shifts.
  // If daylight savings starts sometime between date1 and date2 then diff/millisecondsInDay is some integer plus ~23/24
  //      - in this case daysBetween should return one more than that integer
  // If daylight savings ends sometime between date1 and date2 then diff/millisecondsInDay is some integer plus ~1/24
  //      - in this case daysBetween should return that integer
  // In all other cases diff/millisecondsInDay is exactly an integer and we should return that
  //
  return Math.round(diff/millisecondsInDay);
}

function summarizeSliceSpace(sliceSpace: Array<SliceSpecification>) {
  const presentMetrics: string[] = [];
  const presentSlicingDimensions = new Map<string, string[]>();
  for (const sliceSpec of sliceSpace) {
    for (const metric of sliceSpec.metrics) {
      const metric_label = METRIC_MAP.get(metric as string)?.label ?? metric;
      presentMetrics.push(metric_label);
      for (const dimension of Object.keys(sliceSpec.slice)) {
        const dimension_label = SLICING_DIMENSION_MAP.get(dimension)?.label ?? dimension;
        const dimValue = sliceSpec.slice[dimension];
        if (dimValue) {
          const dimensionLabels = presentSlicingDimensions.get(dimension_label) ?? [];
          const dimension_value_label = SLICING_DIMENSION_MAP.get(dimension)?.values.get(dimValue) ?? dimValue;
          dimensionLabels.push(dimension_value_label);
          presentSlicingDimensions.set(dimension_label,dedup(dimensionLabels).sort());
        }
      }
    }
  }
  return {
    presentMetrics: dedup(presentMetrics).sort(),
    presentSlicingDimensions: Array.from(presentSlicingDimensions.keys()).sort().map((k) =>
      ({ dimension_label: k, dimension_values: presentSlicingDimensions.get(k)})
    )
  }
}

export function prepareFormattedReportSummaryFields(reportInfo) {
  const rp = reportInfo.report_parameters;

  const parsedStartDate = parseDate(rp.start_date, "yyyyMMdd")
  const parsedEndDate = parseDate(rp.end_date, "yyyyMMdd");
  const total_days = padStart((1 + daysBetween(parsedStartDate,parsedEndDate) - rp.exclude_dates.length).toString(), 2, ' ');
  const start_date = formatDate(parsedStartDate, "yyyy-MM-dd");
  const end_date = formatDate(parsedEndDate, "yyyy-MM-dd");

  let owner;
  if (reportInfo.owner) {
    switch(reportInfo.owner.auth_type) {
      case "spears_portal:midway":
        owner = reportInfo.owner.sub;
        break;
      case "spears_portal:iam":
      case "spears_backend_client":
        owner = reportInfo.owner.spears_client_aws_account;
        break;
      default:
        owner = "-";
    }
  }
  const state = (reportInfo.state == "SUCCEEDED" && reportInfo.errors.length > 0) ? "SUCCEEDED(*)" : reportInfo.state;
  return {
    report_id : reportInfo.report_id,
    description: rp.description ?? "",
    state : state,
    start_date : start_date,
    end_date : end_date,
    total_days : total_days,
    marketplace_name: MARKETPLACE_MAP.get(parseInt(rp.marketplace_id))?.org.toUpperCase(),
    created_on : reportInfo.created_on.split('.')[0],
    updated_on : reportInfo.updated_on.split('.')[0],
    treatment_arms : [...Object.keys(rp.interleaved_arms)].sort((a,b) => (a.length != b.length) ? a.length - b.length : a.localeCompare(b)),
    owner : owner,
    summarizedSliceSpace: summarizeSliceSpace(rp.slice_space)
  }
}

export function stateToColor(state) {
  switch (state) {
    case "SUCCEEDED" : return "green";
    case "SUCCEEDED(*)": return "orange";
    case "FAILED" : return "red";
    default:
      return "black";
  }
}

export function findMatchingTEMModel(result,reportInfo) {
  const temModels = reportInfo.report_results.metadata.tem_models?.models ?? [];

  function isMatchingSlice(resultSliceSpec,modelSliceSpec) {
    // strip all empty dimensions
    const resultSliceKey = JSON.stringify(Object.entries(resultSliceSpec).filter(([k,v]) => v != "").sort());
    const modelSliceKey = JSON.stringify(Object.entries(modelSliceSpec).sort());
    // console.log("resultSliceKey:",resultSliceKey,"\nmodelSliceKey:",modelSliceKey,"\nmatch:",resultSliceKey == modelSliceKey);
    return resultSliceKey == modelSliceKey;
  }

  for (const model of temModels) {
    if (result.metric == model.metric && result.credit_method == model.credit_method &&
        isMatchingSlice(result.slice,model.slice)) {
        return model;
    }
  }
  return null;
}

export function computeLiftStatistics(sion_result:SionTest,conf_level=null) {
  // from https://code.amazon.com/packages/SIONAnalysisReportServiceApp/blobs/3da768d6ab30267fa26db0762163e0ac12684782/--/src/common/stats_utils.py#L97
  // mean_raw, mean_lift = sion_test_result['mean'], sion_test_result['lift']
  // if confidence_level is None:
  //     confidence_level = sion_test_result['conf_level']
  // coeff_raw = scipy.stats.norm.ppf(1.- sion_test_result['conf_level'] / 2.)
  // coeff_lift = scipy.stats.norm.ppf(1.- confidence_level / 2.)
  // se_raw = (sion_test_result['conf_interval'][1] - sion_test_result['conf_interval'][0])/2./coeff_raw
  // se_lift = se_raw / mean_raw * mean_lift
  // conf_int_lift = [mean_lift - coeff_lift * se_lift, mean_lift + coeff_lift * se_lift]
  // return {'conf_interval': conf_int_lift , 'std_error': se_lift, 'mean': mean_lift, 'conf_level':confidence_level}

  // DEBUG
  // console.log("jStat.normal.inv(0.95, 0., 1.) : ",jStat.normal.inv(0.95, 0., 1.));
  // console.log("jStat.normal.cdf(1.6448536269514722, 0., 1.) : ",jStat.normal.cdf(1.6448536269514722, 0., 1.));

  const effective_conf_level = conf_level ?? sion_result.conf_level;
  const coeff_raw = jStat.normal.inv(1.- sion_result.conf_level / 2. , 0., 1.)
  const coeff_lift = jStat.normal.inv(1.- effective_conf_level / 2., 0., 1.);

  const se_raw = ((sion_result.conf_interval[1] - sion_result.conf_interval[0]) / 2.) / coeff_raw;
  const se_lift = se_raw / sion_result.mean * sion_result.lift

  return {
    mean : sion_result.lift,
    conf_level: effective_conf_level,
    conf_interval: [sion_result.lift - coeff_lift * se_lift, sion_result.lift + coeff_lift * se_lift],
    std_error: se_lift
  };
}

export function applyTEMModel(model,sion_result:SionTest,conf_level) {
  // tem_lift = mean_lift*model["tem_coefficient"]
  // coeff = norm.ppf(1.- conf_level / 2.)
  // confidence_se = np.sqrt((mean_lift*model["tem_se_beta"])**2+(model["tem_coefficient"]*se_lift)**2+(model["tem_se_beta"]*se_lift)**2)
  // conf_lower, conf_upper = (tem_lift-coeff*confidence_se), (tem_lift+coeff*confidence_se)
  // mean_lift is just the mean of Lift(A-B), and se_lift is the standard error of Lift(A-B), which is implemented by Nan in stats_utils.py

  const lift_stats = computeLiftStatistics(sion_result,conf_level);

  // console.log("model:",model);
  // console.log("lift_stats:",lift_stats);

  const effective_conf_level = conf_level ?? sion_result.conf_level;
  const coeff = jStat.normal.inv(1.- effective_conf_level / 2., 0., 1.);

  const se_lift = lift_stats.std_error;
  const tem_lift = sion_result.lift * model["tem_coefficient"]

  const confidence_se = Math.sqrt(
  (sion_result.lift * model["tem_se_beta"])**2 + (model["tem_coefficient"] * se_lift)**2 + (model["tem_se_beta"]*se_lift)**2
  );

  return {
    mean : tem_lift,
    conf_level: effective_conf_level,
    conf_interval: [ tem_lift - coeff * confidence_se, tem_lift + coeff * confidence_se],
  };

}

export function isRealNumber(str) {
  return /^\-?[0-9]+(e[0-9]+)?(\.[0-9]*)?$/.test(str);
}

export function isIntegerNumber(value) {
    return /^-?\d+$/.test(value);
}

export function parseRealNumber(inputValue, range) : [number,string[]] {
  const errors : string[] = [];
  const value = parseFloat(inputValue);
  if (isRealNumber(inputValue)) {
    if(!checkRange(value,range)) { errors.push(`number not in range [${range}]`) }
  } else {
    errors.push(`invalid number format`)
  }
  return [value,errors];
}

export function checkRange(value , range: number []){
  return value >= range[0] && value <= range[1];
}

export function validateFloat(value , range?: number [], stripCommas?:false){
  if (value == null || value.length == 0) return true;
  const normalizedValue = stripCommas ? value.replace(/,/g, '') : value;
  if (!isRealNumber(normalizedValue)) return false;
  if (range) { return checkRange(parseFloat(normalizedValue), range); }
}
export function validateInt(value , range?: number []){
  if (value == null || value.length == 0) return true;
  if (!isIntegerNumber(value)) return false;
  if (range) { return checkRange(parseInt(value), range); }
}

let dummyIdCounter = 0;
export function getUniqueId(prefix:string="") {
  ++dummyIdCounter;
  return prefix + Date.now() + "." + dummyIdCounter;
}

export function NBSP(width) {
  return new Array(width).fill(`\u00A0`).join('');
}

/**
 * Make a canonical key from an object by using JSON stringification with stable ordering of object properties
 * @param obj
 */
export function makeKeyFromObject(obj){
  return JSON.stringify(obj,Object.keys(obj).sort());
}

/**
 * Order sion result by slice/metric/credit_method where ordering on slice uses stable ordering on slicing dimensions
 * by using makeKeyFromObject(slice) (this ensures that "no slicing" slices precede all others)
 * @param sionResults
 */
export function sortSionResultsCanonically(sionResults) {
  const makeResultKey = r => JSON.stringify({
    slice_key: makeKeyFromObject(r.slice), metric: r.metric, credit_method: r.credit_method
  }, ["slice_key","metric","credit_method"]);
  return sionResults.sort((a,b)=> makeResultKey(a).localeCompare(makeResultKey(b)))
}
