import { ReactNode } from 'react';
import ReactTooltip from "react-tooltip";
import {Box, Button, Link } from '@amzn/awsui-components-react/polaris';
import {flatten, getUniqueId, intersperseWith, NBSP, stateToColor, unflatten} from "../../common/utils";

export function COLUMN_DEFINITIONS (props,preferences,reportAction) {

  function canDelete(report) {
    return(report.owner == props.currentUser);
  }

  function renderReportDetails(reportSummary) {
    const description = !reportSummary.description ? '' : <>
      <b>Description: </b>
        {intersperseWith(unflatten(reportSummary.description.split("\n")), () => <span key={getUniqueId()}><br/>{NBSP(4)}</span>)}
      <br/>
    </>;
    const metrics = <><b>Metrics:</b> {reportSummary.summarizedSliceSpace.presentMetrics.join("; ")}<br/></>;
    const slices = reportSummary.summarizedSliceSpace.presentSlicingDimensions.length == 0 ? '' : <>
      <b>Slices:</b><br/>
        {intersperseWith(reportSummary.summarizedSliceSpace.presentSlicingDimensions.map((s) =>
          <span key={getUniqueId()}>{NBSP(6)}{s.dimension_label}: {s.dimension_values.join("; ")}</span>), () => <br key={getUniqueId()}/>)}
    </>;
    return <>
      {description}
      {metrics}
      {slices}
    </>;
  }

  function ReportListField(props) {
    function updateBgColor(event, color) {
      if (event.target instanceof HTMLElement) {
        const tableRowElement = (event.target as HTMLElement).closest('.SIONReportListField');
        if (tableRowElement?.parentElement?.parentElement) {
          tableRowElement.parentElement.parentElement!.style.backgroundColor = color;
        }
      }
    }

    const {reportSummary, children, style, ...other_props} = props;
    const isToolTipOn = !preferences.visibleContent.includes('details');
    const rowStyle = { ...style};

    return <div className="SIONReportListField" style={rowStyle} data-tip data-for={reportSummary.report_id}
        onMouseEnter={ e => updateBgColor(e, "lightblue") }
        onMouseLeave={ e => updateBgColor(e, "") }
        onClick={ e => reportAction(e,'view',reportSummary.report_id) }>
        {isToolTipOn && <ReactTooltip id={reportSummary.report_id} place="top" type="info" effect="solid">
          <span style={{fontSize:"115%"}}>{renderReportDetails(reportSummary)}</span>
        </ReactTooltip>}
        {children}
    </div>
  }
  return [
    {
      id: 'view_link',
      header: '',
      cell: item => <Link href={`${window.location.origin}/#/view/${item.report_id}`} target="_blank">View</Link>,
    },

    {
      id: 'marketplace_name',
      header: 'Marketplace',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.marketplace_name}
        </ReportListField>,
      sortingField: 'marketplace_name',
      //minWidth: '100px'
    },
    {
      id: 'start_date',
      header: 'Start Date',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.start_date}
        </ReportListField>,
      sortingField: 'start_date',
      //minWidth: '100px'
    },
    {
      id: 'end_date',
      header: 'End Date',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.end_date}
        </ReportListField>,
      sortingField: 'end_date',
      //minWidth: '100px'
    },
    {
      id: 'total_days',
      header: '# Days',
      cell: item =>
        <ReportListField reportSummary={item}>
          {item.total_days}
        </ReportListField>,
      sortingField: 'total_days',
      //minWidth: '100px'
    },
    {
      id: 'state',
      header: 'Status',
      cell: item =>  <ReportListField style={{color: stateToColor(item.state)}} reportSummary={item}>
            {item.state}
      </ReportListField>,
      sortingField: 'state',
      //minWidth: '100px'
    },
    {
      id: 'details',
      header: 'Report Details',
      cell: item =>  <ReportListField reportSummary={item}>
          {renderReportDetails(item)}
        </ReportListField>,
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: item => <div>
          {/*<span title="View report">
            <Button variant="inline-icon" iconName="file-open" onClick={(e) => reportAction(e,'view',item.report_id)}/>
          </span>*/}
          <span title="Clone report">
            <Button variant="inline-icon" iconName="copy" onClick={(e) => reportAction(e,'submit',item.report_id)}/>
          </span>
          <span title="Delete report">
            <Button variant="inline-icon" iconName="status-negative" disabled={!canDelete(item)} onClick={(e) => reportAction(e,'delete',item.report_id)}/>
          </span>
      </div>,
      //minWidth: '40px'
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.owner}
        </ReportListField>,
      sortingField: 'owner',
    },
    {
      id: 'updated_on',
      header: 'Last Updated',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.updated_on}
        </ReportListField>,
      sortingField: 'updated_on',
      //minWidth: '100px'
    },
    {
      id: 'report_id',
      header: 'Report Id',
      cell: item =>  <ReportListField reportSummary={item}>
          {item.report_id}
        </ReportListField>,
    },

  ];
}

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
  action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
